<template>
  <v-app>
    <v-navigation-drawer
      v-model="sideNav"
      temporary
      fixed
    >
      <v-list color="primary">
        <v-list-tile
          v-for="item in menuItems"
          :key="item.title"
          >
          <v-btn dark dense :to="item.link">
            <v-list-tile-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>{{ item.title }}</v-list-tile-content>
          </v-btn>
          <v-divider></v-divider>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar dark color="primary" elevation="5" dense fixed height="50px">
<!--      <v-toolbar-side-icon-->
<!--        class="hidden-sm-and-up "-->
<!--        @click.stop="sideNav = !sideNav"></v-toolbar-side-icon>-->
      <v-app-bar-nav-icon  class="hidden-sm-and-up " @click.stop="sideNav = !sideNav"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/" tag="span" style="cursor: pointer; font-family: -apple-system,sans-serif; font-weight:bold;color:#fff;">CITE SAINTE AUGUSTINE</router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-xs-only">
        <v-btn
          v-for="item in menuItems"
          :key="item.title"
          dark
          dense
          color="primary"
          class="mt-2 mr-2"
          :to="{name: item.link}">
          <v-icon left dark>{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <main>
      <router-view></router-view>
    </main >
    <Footer></Footer>
  </v-app>
</template>

<script>
// eslint-disable-next-line import/extensions
// eslint-disable-next-line import/extensions
import { mdiWalletTravel } from '@mdi/js'
// eslint-disable-next-line import/extensions
import Footer from '@/views/home/Footer'
import * as components from 'vuetify'
export default {
  name: 'Home',
  components: {
    Footer,
  },
  data() {
    return {
      sideNav: false,
      menuItems: [
        { icon: mdiWalletTravel, title: 'Accueil', link: 'landing' },
        { icon: mdiWalletTravel, title: 'Disponibilité', link: 'availability' },
        { icon: mdiWalletTravel, title: 'S\'Enregistrer', link: 'user_register' },
        { icon: mdiWalletTravel, title: 'Personnel', link: 'administration' },
      ],
    }
  },
}
</script>

<style scoped>

</style>
