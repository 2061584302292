<template>
<div >
  <v-card >
    <v-footer
        color="primary"
        style="height:10%;"
    >
      <v-row dense>
        <v-col cols="12" sm="12" md="4">
          <v-card
              flat
              tile
              class="transparent  white--text text-center"
              dense
          >
            <v-card-text>
              <v-btn
                  class="mx-4 white--text"
                  icon
              > <a  href="http://www.facebook.com/successaint/" target="_blank">
                <v-icon size="24px"
                        color="white">
                {{ icons[0] }}
                </v-icon></a>
              </v-btn>
              <v-btn
                  class="mx-10 white--text"
                  icon
                  style="margin-right: 5rem; margin-left: 5rem;"
              >
                <v-icon size="24px" color="white">
                  {{ icons[1] }}
                </v-icon> <span class="text-lowercase" >citesainte</span>
              </v-btn>
              <v-btn
                  class="mx-15 white--text"
                  icon
              >
                <v-icon size="24px" color="white">
                  {{ icons[2] }}
                </v-icon> <span>+225 07 00 54 87 90</span>
              </v-btn>
            </v-card-text>
            <v-card-text>
              <p class="mx-4 white--text">
                Situation geographique<br>
                non loin de 100 logement garçon

              </p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-card
              flat
              tile
              dense
              class="transparent  white--text text-center"
          >
            <v-card-text class=" mx-4 white--text">
              <div style="display: flex; justify-content: center; align-items:center;">
                <img src="@/assets/images/logo/logo_1.jpeg" alt="stAugustine" style="width:75px;height:70px; overflow:hidden;border:none; border-radius:25%;">
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-card
              dense
              flat
              tile
              class="transparent  white--text text-center"
          >
            <v-card-text class="white--text">
              <p class="mx-8">
                {{ new Date().toLocaleDateString() }} — <strong>Cite Sainte Augustine</strong>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-footer>
  </v-card>
</div>
</template>

<script>
import { mdiFacebook, mdiInstagram, mdiWhatsapp } from '@mdi/js'

export default {
  name: 'Footer',
  components: {
    // mdiInstagram, mdiFacebook, mdiWhatsapp,
  },
  data: () => ({
    icons: [
      mdiFacebook,
      mdiInstagram,
      mdiWhatsapp,
    ],
  }),
}
</script>

<style scoped>

</style>
